import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

const MdInfo = () => {
  const [storageContent, setStorageContent] = useState([]);
  const { id } = useParams(); // Obtenemos el id de los parámetros de la URL

  useEffect(() => {
    const content = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      content.push({ key, value });
    }
    setStorageContent(content);
  }, []);

  /*  if (isLoading) {
        return <div>Cargando permisos...</div>;
  }*/

  return (
    <Box spacing={5} margin={5}>
      <h2>Información de expedientes</h2>
      <h3>ID recibido: {id}</h3> {/* Mostramos el id recibido de la URL */}
      <div>
        <h1>Local Storage</h1>
        <ul>
          {storageContent.map((item, index) => (
            <li key={index}>
              <strong>{item.key}:</strong> {item.value}
            </li>
          ))}
        </ul>
      </div>


    </Box>
  );
};

export default MdInfo;
