import React, { useEffect, useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Grid, Autocomplete } from '@mui/material';
import { getDepartamentos } from "../../../services/DepartamentoService.js";
import { getProvincias } from "../../../services/ProvinciaService.js";

const Domicilio = ({ data, onChange }) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);

  // Maneja los cambios en los campos de texto y actualiza itemCrudLocal
  const handleDomicilioChange = (e) => {
    const newData = {
      ...data,
      [e.target.name]: e.target.value,
    };
    onChange(newData);
  };

  // Obtiene los departamentos
  const fetchDepartamentos = async () => {
    try {
      const response = await getDepartamentos();
      setDepartamentos(response);
    } catch (error) {
      console.error('Error al traer Departamentos', error);
    }
  };

  useEffect(() => {
    fetchDepartamentos();
  }, []);

  // Obtiene las provincias
  const fetchProvincias = async () => {
    try {
      const response = await getProvincias();
      setProvincias(response);
    } catch (error) {
      console.error('Error al traer Provincias', error);
    }
  };

  useEffect(() => {
    fetchProvincias();
  }, []);

  return (
    <Box sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="id_domicilio_persona_service"
            variant="outlined"
            size="small"
            name="id_domicilio_persona_service"
            value={data?.id || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Calle"
            variant="outlined"
            size="small"
            name="calle"
            value={data?.calle || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Número"
            variant="outlined"
            size="small"
            name="numero"
            value={data?.numero_calle || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Barrio"
            variant="outlined"
            size="small"
            name="barrio"
            value={data?.barrio || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Manzana"
            variant="outlined"
            size="small"
            name="manzana"
            value={data?.manzana || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Casa"
            variant="outlined"
            size="small"
            name="casa"
            value={data?.casa || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Edificio"
            variant="outlined"
            size="small"
            name="edificio"
            value={data?.monoblock || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Piso"
            variant="outlined"
            size="small"
            name="piso"
            value={data?.piso || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Departamento"
            variant="outlined"
            size="small"
            name="dpto"
            value={data?.dpto || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Localidad"
            variant="outlined"
            size="small"
            name="localidad"
            value={data?.localidad || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" size="small">
            <Autocomplete
              options={departamentos}
              getOptionLabel={(option) => option.descripcion || ""}
              onChange={(event, newValue) => {
                onChange({
                  ...data,
                  id_departamento: newValue ? newValue.id : "",
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Departamento" variant="outlined" size="small" />
              )}
              value={departamentos.find(dept => dept.id === data.id_departamento) || null}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" size="small">
            <Autocomplete
              options={provincias}
              getOptionLabel={(option) => option.descripcion || ""}
              onChange={(event, newValue) => {
                onChange({
                  ...data,
                  id_provincia: newValue ? newValue.id : "",
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Provincia" variant="outlined" size="small" />
              )}
              value={provincias.find(prov => prov.id === data.id_provincia) || null}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Código Postal"
            variant="outlined"
            size="small"
            name="codigo_postal"
            value={data?.codigo_postal || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Otros Datos de Domicilio"
            variant="outlined"
            size="small"
            name="descripcion"
            multiline
            rows={3}
            value={data?.descripcion || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Teléfono"
            variant="outlined"
            size="small"
            name="telefono"
            value={data?.telefono || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Teléfono Móvil"
            variant="outlined"
            size="small"
            name="telefonoMovil"
            value={data?.telefonoMovil || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Domicilio Electrónico"
            variant="outlined"
            size="small"
            name="domicilio_electronico"
            value={data?.domicilio_electronico || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Domicilio Contractual"
            variant="outlined"
            size="small"
            name="domicilio_contractual"
            value={data?.domicilio_contractual || ""}
            onChange={handleDomicilioChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Domicilio;
