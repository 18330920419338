import Keycloak from "keycloak-js";
const urlHome = import.meta.env.VITE_HOME_URL;

const keycloak = new Keycloak({
  url: import.meta.env.VITE_KEYCLOAK_AUTH_SERVER_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_RESOURCE
});

const setUserLocalStorage = (keycloak) => {
  localStorage.setItem('UserName', keycloak.tokenParsed?.email || '');
  localStorage.setItem('Token', keycloak.token || '');
  localStorage.setItem('Usuario', keycloak.tokenParsed?.preferred_username || '');// Cambiado a 'UserPre' para almacenar el atributo 'pre' del tokenParsed

};
const clearUserLocalStorage = () => {
  localStorage.clear();
};

const keycloakObject = {
  initKeycloak: async () => {
    try {
      const authenticated = await keycloak.init({ onLoad: "login-required", checkLoginIframe: true });
      console.log(authenticated ? "User authenticated" : "User not authenticated");
      if (authenticated) {
        setUserLocalStorage(keycloak);
      } else {
        console.error("User not authenticated");
      }
      return authenticated;
    } catch (error) {
      console.error("Failed to initialize Keycloak", error);
      return false;
    }
  },

  doLogin: () => keycloak.login(),

  doLogout: () => {
    clearUserLocalStorage();
    // keycloak.logout();
    keycloak.logout({ redirectUri: urlHome });
  },

  getToken: () => keycloak.token || "",
  getUserId: () => keycloak.subject || "",
  getInstance: () => keycloak,
  isLoggedIn: () => !!keycloakObject.getToken(),
  expira: () => keycloak.isTokenExpired(),

  updateToken: async (successCallback) => {
    try {
      await keycloak.updateToken(5);
      localStorage.setItem('Token', keycloak.token || '');
      successCallback();
    } catch (error) {
      keycloakObject.doLogin();
    }
  },
  getTokenParsed: () => keycloak.tokenParsed,
};

export default keycloakObject;
