import React, { useState } from 'react';
import {
  Container, CssBaseline, Typography, Box, TextField,  Radio, RadioGroup,
  FormControlLabel, FormControl, FormLabel, Select, MenuItem, 
} from '@mui/material';

const FormularioIngresoCausas = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [fuero, setFuero] = useState('');
  const [categoria, setCategoria] = useState('');
  const [materia, setMateria] = useState('');
  const [precautoria, setPrecautoria] = useState('');
  const [nocturna, setNocturna] = useState('');
  const [tipoPersona, setTipoPersona] = useState('');
  const [menorEdad, setMenorEdad] = useState('');
  const [actor, setActor] = useState({
    apellido: '',
    nombre: '',
    seudonimo: '',
    tipoDoc: 'DNI',
    nroDoc: '',
    cuil: '',
    domicilioReal: '',
    domicilioElectronico: ''
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setActor({
      ...actor,
      [name]: value
    });
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4
        }}
      >
        <Typography component="h1" variant="h5">
          FORMULARIO DE INGRESO DE CAUSAS
        </Typography>
        <Typography component="h6" variant="subtitle1">
          Implementación CPCCyT - Ley 9.001
        </Typography>
        <Typography component="h6" variant="subtitle2">
          Acordadas N° 15.218 y N° 28.944
        </Typography>

        <form>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">Fuero</FormLabel>
            <RadioGroup
              row
              aria-label="fuero"
              name="fuero"
              value={fuero}
              onChange={(e) => setFuero(e.target.value)}
            >
              <FormControlLabel value="civil" control={<Radio />} label="CIVIL" />
              <FormControlLabel value="paz" control={<Radio />} label="PAZ" />
              <FormControlLabel value="laboral" control={<Radio />} label="LABORAL" />
              
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <FormLabel>Categoría de Juicio</FormLabel>
            <Select
              id="categoria"
              name="categoria"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            >
              <MenuItem value="">--- Seleccione Fuero ---</MenuItem>
              {/* Add other categories as needed */}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <FormLabel>Materia</FormLabel>
            <Select
              id="materia"
              name="materia"
              value={materia}
              onChange={(e) => setMateria(e.target.value)}
            >
              <MenuItem value="">--- Seleccione Categoría ---</MenuItem>
              {/* Add other materias as needed */}
            </Select>
          </FormControl>

          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">Medida Precautoria</FormLabel>
            <RadioGroup
              row
              aria-label="precautoria"
              name="precautoria"
              value={precautoria}
              onChange={(e) => setPrecautoria(e.target.value)}
            >
              <FormControlLabel value="si" control={<Radio />} label="SI" />
              <FormControlLabel value="no" control={<Radio />} label="NO" />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">Art. 61 Inc. III del CPCCyT</FormLabel>
            <RadioGroup
              row
              aria-label="nocturna"
              name="nocturna"
              value={nocturna}
              onChange={(e) => setNocturna(e.target.value)}
            >
              <FormControlLabel value="si" control={<Radio />} label="SI" />
              <FormControlLabel value="no" control={<Radio />} label="NO" />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">Tipo de Persona</FormLabel>
            <RadioGroup
              row
              aria-label="tipoPersona"
              name="tipoPersona"
              value={tipoPersona}
              onChange={(e) => setTipoPersona(e.target.value)}
            >
              <FormControlLabel value="humana" control={<Radio />} label="HUMANA" />
              <FormControlLabel value="juridica" control={<Radio />} label="EXISTENCIA IDEAL" />
            </RadioGroup>
          </FormControl>

          {tipoPersona === 'humana' && (
            <>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Menor de Edad</FormLabel>
                <RadioGroup
                  row
                  aria-label="menorEdad"
                  name="menorEdad"
                  value={menorEdad}
                  onChange={(e) => setMenorEdad(e.target.value)}
                >
                  <FormControlLabel value="si" control={<Radio />} label="SI" />
                  <FormControlLabel value="no" control={<Radio />} label="NO" />
                  <FormControlLabel value="emancipado" control={<Radio />} label="EMANCIPADO" />
                </RadioGroup>
              </FormControl>

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="apellido_actor"
                label="APELLIDO/S"
                name="apellido"
                value={actor.apellido}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="nombre_actor"
                label="NOMBRE/S"
                name="nombre"
                value={actor.nombre}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="seudonimo_actor"
                label="SEUDÓNIMO (no obligatorio)"
                name="seudonimo"
                value={actor.seudonimo}
                onChange={handleInputChange}
              />
              <FormControl fullWidth margin="normal">
                <FormLabel>Tipo Documento</FormLabel>
                <Select
                  id="tipo_doc_actor"
                  name="tipoDoc"
                  value={actor.tipoDoc}
                  onChange={handleInputChange}
                >
                  <MenuItem value="DNI">DNI</MenuItem>
                  <MenuItem value="LC">LC</MenuItem>
                  <MenuItem value="LE">LE</MenuItem>
                  <MenuItem value="CE">CE</MenuItem>
                  <MenuItem value="PAS">PAS</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="nro_doc_actor"
                label="NÚMERO"
                name="nroDoc"
                value={actor.nroDoc}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cuil_actor"
                label="CUIL/CUIT N°"
                name="cuil"
                value={actor.cuil}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="real_actor"
                label="DOMICILIO REAL"
                name="domicilioReal"
                value={actor.domicilioReal}
                onChange={handleInputChange}
                multiline
                rows={3}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="correo_actor"
                label="DOMICILIO ELECTRÓNICO"
                name="domicilioElectronico"
                value={actor.domicilioElectronico}
                onChange={handleInputChange}
              />
            </>
          )}

       
        </form>
      </Box>

     
    </Container>
  );
};

export default FormularioIngresoCausas;
