
import { urlBackendUsuarios } from "../config";
import HttpService from "./HttpService";

const epName = "/usuario/";
const nameItemsText = "Usuarios";
const nameItemText = "Usuario";
const itemGender = "M";
const elLa = itemGender === "M" ? "el" : "la";
const unUna = itemGender === "M" ? "un" : "una";
//
// #############################################################################
//
export const getUsuarios = async (params) => {
	let _params = {};

	let _errorMessage = `Se produjo un error al obtener ${nameItemsText}`;

	if (params.nombre) {
		_params.nombre = params.nombre;
	}

	if (params.apellido) {
		_params.apellido = params.apellido;
	}

	if (params.username) {
		_params.username = params.username;
	}

	if (params.origenDatos) {
		_params.origen_datos = params.origenDatos;
	}

	if (params.estado) {
		let estado =
			params.estado === "habilitado"
				? "H"
				: params.estado === "deshabilitado"
					? "D"
					: "";
		_params.habilitado = estado;
	}

	if (params.rows) {
		_params.rows = params.rows;
	}

	if (params.first) {
		_params.first = params.first;
	}

	try {


		HttpService.configure();
		const client = HttpService.getAxiosClient();
		console.log("entro al ty de usuarios");
		const response = await client.get(`${urlBackendUsuarios}${epName}`, {
			params: params,
		});

		if (response.status === 200) {
			let _error = "error_code" in response.data;
			if (!_error) {

				response.ok = true;
				return response;
			}
		}
		console.log('----------------')
		console.log('response: ', response)
		console.log(_errorMessage);
		console.log(response);

		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	} catch (error) {
		console.log(_errorMessage);
		if (error.response) {

			console.log(error.response);
		} else if (error.request) {

			console.log(error.request);
		} else {

			console.log('error');
		}
		let response = {};
		response.ok = false;
		response.messageText = _errorMessage;
		response.messageSeverity = "error";
		return response;
	}
};
//
// #############################################################################
//

