import { Navigate } from "react-router-dom";
import MatxLayout from "../components/MatxLayout/MatxLayout";

// Session pages
import NotFound from "../views/sessions/NotFound";
import Demanda from "./components/demandas/Demanda";
import MdTable from "./mdtable/MdTable";
import MdInfo from "./mdtable/MdInfo";
import TableExpediente from "./tableExpedientes/TableExpediente";
import FormularioIngresoCausas from "./tableExpedientes/formDemandas";

const routes = [
  {
    element: (
      <MatxLayout />
    ),
    children: [
      { path: "/informacion/:id", element: <MdInfo /> },
      { path: "/inicio", element: <Demanda /> },
      { path: "/project/components/demandas/Demanda", element: <Demanda /> },
      { path: "/project/tableExpedientes/TableExpediente", element: <TableExpediente/> },
          { path: "/project/mdtable/MdTable", element: <MdTable /> },

    ]
  },
  { path: "/project/tableExpedientes/formDemandas", element: <FormularioIngresoCausas/> },

  // session pages route
  { path: "/session/404", element: <NotFound /> },

  { path: "/", element: <Navigate to="inicio" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
