import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";
import Domicilio from "../domicilio/Domicilio";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {  getPersonas } from "../../../services/PersonasService.js";
import { getMateriaVinculo } from "../../../services/MateriaVinculoService.js";


const initialState = {

numero_cuil:"",
nombre:"",
apellido:"",
id_tipo_vinculo:"",
email: "",
telefono_movil:"",
telefono:"",
  domicilios: {legal: {}},
  domicilioLegal:{}, 
};


const nameItemText = "Patrocinante";

const PatrocinanteDialog = ({
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
  id_fuero,
  id_materia,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [materiavinculoValue, setMateriaVinculoValue] = useState(null);
  const [materiavinculoOptions, setMateriaVinculoOptions] = useState([]);
  useEffect(() => {
    if (actionCrud === "create") {
      setItemCrudLocal(initialState);
    } else {
      setItemCrudLocal(itemCrud);
    }
  }, [actionCrud, itemCrud]);

  const handleCloseDialog = () => {
    onClose();
  };
 
  useEffect(() => {  console.log
    const fetchVinculoMateria = async () => {
      if (id_fuero && id_materia) {
        try {
          const vinculos = await getMateriaVinculo({
             id_fuero,id_materia,
          });
       
          setMateriaVinculoOptions(vinculos);
        } catch (error) {
          console.error('Error al traer los vínculos de la materia', error);
        }
      } else {
        setMateriaVinculoOptions([]);
      }
    };
    fetchVinculoMateria();
  }, [id_fuero, id_materia]);


  const handleChange = (e, newValue = null, fieldName = null) => {
    const { name, value } = e.target || {};

    if (newValue) {

      setItemCrudLocal({
        ...itemCrudLocal,
        [fieldName]: newValue.value,
      });
    } else {
    
      setItemCrudLocal({
        ...itemCrudLocal,
        [name]: value,
      });
    }


  };
  const handleReset = () => {
    setItemCrudLocal(initialState);
  };

  const handleSave = () => {
    if (actionCrud === "view") return;

    const currentErrors = {};

    if (itemCrudLocal.nombre.trim() === "") {
      currentErrors.nombre = "El nombre es obligatorio";
    }

    if (itemCrudLocal.numero_cuil.trim() === "") {
      currentErrors.numero_cuil = "Cuil debe ser obligatorio";
    }

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      setMessage({
        severity: "error",
        messageText: "Por favor, completa todos los campos obligatorios",
      });
      setShowMessage(true);
      return;
    }

    setIsDoingSomething(true);
    setTimeout(() => {
      setIsDoingSomething(false);
      onSave(itemCrudLocal);
    }, 1000);

    setMessage({
      messageText: "Datos guardados exitosamente",
      severity: "success",
    });
    setShowMessage(true);
  };


  const handleSearchPatrocinante = async () => {
    let telefono_movil = "";
    let telefono = "";
    try {
    
      const searchData = itemCrudLocal.numero_cuil;  
   
        if (!searchData) {
          setMessage({
            severity: "warning",
            messageText: "Incorpore número de documento",
          });
    
        return; 
      }
          
      const response =  await getPersonas({ numero_cuil: searchData }) 
       
      if (response.status === "exists") {console.log (response.status);        
       const  personaData = response.data;
       const domicilioLegal = personaData.domicilios?.find(domicilio => domicilio.tipo === 'legal') || null;
         
       personaData.telefonos.forEach((tel) => {
        if (tel.tipo === "movil") {
          telefono_movil = tel.numero;
        } else if (tel.tipo === "fijo") {
          telefono = tel.numero;
        }
      });
        setItemCrudLocal((prevState) => ({
          ...prevState,
          nombre: personaData.nombre || "",
          apellido: personaData.apellido || "",
          email: personaData.email || "",
          telefono_movil:telefono_movil,
          telefono:telefono,
          domicilioLegal, // Asignar domicilioLegal (null si no existe)

           
        
        }));
        setShowMessage(true);
  
        setMessage({
          severity: "success",
          messageText: "Datos encontrados",
        });
  
      } else {
           // Si personaData es null o vacío, inicializa itemCrudLocal 
        setItemCrudLocal((prevState) => ({
          ...prevState,
          nombre: "",
          apellido: "",
          numero_cuil: "",
           domicilioLegal: null,
      
                  }));
        setShowMessage(true);
        setMessage({
          severity: "error",
          messageText: "No se encontraro datos para el documento solicitado",
                  });
      }
    } catch (error) {
      console.error('Error al buscar persona por documento', error);
      setMessage({
        severity: "error",
        messageText: "Error al buscar datos por documento",
      });
    }
  };
  
  const handleDomicilioChange = (data) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [`domicilioLegal`]: data,
    });
  };

  return (
    <Dialog
      open={true}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      aria-labelledby="form-dialog-title"
    >
      {isDoingSomething && <LinearProgress />}
      <DialogTitle
        id="form-dialog-title"
        
      >
        {actionCrud === "create"
          ? `Agregar ${nameItemText}`
          : actionCrud === "view"
          ? `Ver ${nameItemText}`
          : `Modificar ${nameItemText}`}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{ position: "absolute", right: 8, top: 8, color: '#53522C' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
        <FormControl fullWidth error={!!errors.vinculo}>
    <Autocomplete
      id="vinculo"
      size="small"
      name="vinculo"


      value={
        materiavinculoOptions.find(
          option => option.value === itemCrudLocal.materiavinculoValue
        ) || null
      }
      onChange={(event, newValue) => setMateriaVinculoValue(newValue ? newValue.value : null)}
      options={materiavinculoOptions}
      noOptionsText="No hay opciones disponibles"
      getOptionLabel={(option) => option.label || ""}
      renderInput={(params) => (
        <TextField {...params} label="Vinculo*" />
      )}
      isOptionEqualToValue={(option, value) => option.value === value}
      disabled={!!itemCrudLocal.vinculo || actionCrud === "view"} 
    />
    <FormHelperText>{errors.vinculo || "Seleccione vinculo"}</FormHelperText>
  </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>

<TextField

size="small"
label="Búsqueda por Cuil"
id="numero_cuil"
name="numero_cuil"
value={itemCrudLocal.numero_cuil || ''}
onChange={handleChange}
placeholder="busqueda por CUIL solo numeros"
required
error={!!errors.numero_cuil}
helperText={errors.numero_cuil}
disabled={!!itemCrudLocal.nombre || actionCrud === "view"} 

fullWidth
InputProps={{
// Lupa (Search) debe estar como startAdornment, antes del texto

startAdornment: (
<InputAdornment position="start">
   <Tooltip title="Buscar persona">
     <IconButton onClick={handleSearchPatrocinante} color="primary">
       <SearchIcon />
     </IconButton>
   </Tooltip>
  
 </InputAdornment>
),endAdornment: (
<InputAdornment position="end">
 
   {itemCrudLocal.numero_cuil && (
     <Tooltip title="Resetear búsqueda">
       <IconButton onClick={handleReset} color="warning">
         <ClearIcon />
       </IconButton>
     </Tooltip>
   )}
 </InputAdornment>
),
}}
/>


</Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Nombre"
              name="nombre"
              value={itemCrudLocal.nombre}
              onChange={handleChange}
              fullWidth
                size="small"
              required
              error={!!errors.nombre}
              helperText={errors.nombre || "Ingrese su nombre completo"}
              inputProps={{ "aria-label": "Nombre completo" }}
              disabled={actionCrud === "view"}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Apellido"
              name="apellido"
              value={itemCrudLocal.apellido}
              onChange={handleChange}
              fullWidth
                size="small"
              required
              error={!!errors.apellido}
              helperText={errors.apellido || "Ingrese el apellido"}
              disabled={actionCrud === "view"}
            />
          </Grid>
          

          {/* Acordeón para domicilios */}
          

          <Grid item xs={12}>
          <Accordion  sx={{
      boxShadow: 'none', // Eliminar sombra
      border: 'none', // Eliminar borde
      borderBottom: '2px solid', // Agregar un borde inferior
      borderColor: 'acordeon.main', // Color del borde (puedes cambiarlo según tus necesidades)
    }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              
              <Typography>
  {itemCrudLocal.domicilioLegal && Object.keys(itemCrudLocal.domicilioLegal).length > 0 ? (
    <>
      Domicilio LEGAL{" "}
      <Typography component="span" color="primary">: Datos encontrados</Typography>
    </>
  ) : (
    <>
      Domicilio LEGAL{" "}
      <Typography component="span" color="primary">: Agregar nuevo</Typography>
    </>
  )}
</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Domicilio
                  data={itemCrudLocal.domicilioLegal && Object.keys(itemCrudLocal.domicilioLegal).length > 0 
                    ? itemCrudLocal.domicilioLegal 
                    : itemCrudLocal.domicilios}
              onChange={(data) => handleDomicilioChange(data)}
            />
              </AccordionDetails>
            </Accordion>
          </Grid>

          
          
        </Grid>
      </DialogContent>
{actionCrud !== "view" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,      
            pt: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Cancelar sin guardar los cambios">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={handleCloseDialog}
                aria-label="Cancelar"
              >
                Cancelar
              </Button>
            </Tooltip>
            
          </Box>
          <Tooltip title="Guardar la información ingresada">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
              disabled={isDoingSomething || Object.keys(errors).length > 0}
              aria-label="Guardar"
            >
              {isDoingSomething
                ? "Guardando..."
                : actionCrud === "create"
                ? "Agregar"
                : "Actualizar"}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Dialog>
  );
};

export default PatrocinanteDialog;
