import { InputAdornment, Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, TextField, Tooltip, FormControl, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Typography, Autocomplete, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";
import Domicilio from '../domicilio/Domicilio.jsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getMateriaVinculo } from "../../../services/MateriaVinculoService.js";
import Message from "../../../utils/Message.jsx";
import { getPersonas, getTipoPersona, getPersonasCuit } from "../../../services/PersonasService.js";

const initialState = {
  id_actor: "", 
  tipo_persona_fisica: "", 
  numero_cuil: "",
  numero_documento: "",
  id_nacionalidad: "",
  persona_juridica: "",
  nombre: "",
  apellido: "",
  vinculo: null,
  id_tipo_vinculo: "initial_id_tipo_vinculo",
  persona_fisica: "true",
  tipoPersonActor: "Fisica",
  tipDocumento: "",
  numero: "",
  razonSocial: "",
  cuit: "", 
  email: "",
  domicilios: { real: {}, legal: {}, especial: {}, social: {} },
  domicilioReal: {},
  domicilioLegal: {},
  domicilioSocial: {},
  domicilioEspecial: {},

};



const nameItemText = "Actor";
const intemGender = "M";
const ActorDialog = ({
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
  showMessage,
  message,
  id_fuero,
  id_materia,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [materiavinculoValue, setMateriaVinculoValue] = useState(null);
  const [materiavinculoOptions, setMateriaVinculoOptions] = useState([]);
  const [tipoPersonaOptions, setTipoPersonaOptions] = useState([]);
  const [tipoPersonaValue, setTipoPersonaValue] = useState(null);

  const [selectedNacionalidad, setSelectedNacionalidad] = useState({
    argentino: true,
    extranjero: false,
  });

  // Manejador para seleccionar solo una opción
  const handleNacionalidadChange = (event) => {
    const { name } = event.target;
    setSelectedNacionalidad({
      argentino: name === 'argentino',
      extranjero: name === 'extranjero',
    });
  };

  useEffect(() => {
    setItemCrudLocal(initialState);
  }, [selectedNacionalidad]);


  useEffect(() => {
    const fetchTipoPersona = async () => {
      const options = await getTipoPersona();
      setTipoPersonaOptions(options);
    };

    fetchTipoPersona();
  }, []); // Se ejecuta solo una vez cuando el componente se monta



  useEffect(() => {
    if (actionCrud === "create") {
      setItemCrudLocal(initialState);
    } else {
      setItemCrudLocal(itemCrud);
    }
  }, [actionCrud, itemCrud]);

  const handleCloseDialog = () => {
    onClose();
  };


  useEffect(() => {
    const fetchVinculoMateria = async () => {

      if (id_fuero && id_materia) {
        try {
          const vinculos = await getMateriaVinculo({
            id_fuero, id_materia,
          });

          setMateriaVinculoOptions(vinculos);
        } catch (error) {
          console.error('Error al traer los vínculos de la materia', error);
        }
      } else {
        setMateriaVinculoOptions([]);
      }
    };
    fetchVinculoMateria();
  }, [id_fuero, id_materia]);



  const handleReset = () => {
    setItemCrudLocal(initialState);
  };

  const handleResetJuridica = () => {
    setItemCrudLocal((prevState) => ({
      ...prevState, // Copia el estado anterior
      cuit: "",
      razonSocial: "",
      domicilioReal: null,
      domicilioLegal: null,
      domicilioSocial: null,
      domicilioEspecial: null,  // Solo cambia el campo cuit
    }));
  };




  const handleChange = (e, newValue = null, fieldName = null) => {
    const { name, value } = e.target || {};

    if (newValue) {
      // Si `newValue` viene de un Autocomplete, actualiza el campo especificado (por ejemplo, tipoPersonActor)
      setItemCrudLocal({
        ...itemCrudLocal,
        [fieldName]: newValue.value,
      });
    } else {
      // Si es un cambio de texto o un input regular
      setItemCrudLocal({
        ...itemCrudLocal,
        [name]: value,
      });
    }
  };


  const handleSearchJuridica = async () => {
    try {

      const searchData = itemCrudLocal.cuit
      let telefono_movil = "";
      let telefono = "";

      if (!searchData) {
        setMessage({
          severity: "warning",
          messageText: "Incorpore número de documento",
        });

        return;
      }


      const response = await getPersonasCuit({ cuit: searchData });

      if (response.status === "exists") {

        const personaData = response.data;

        personaData.telefonos.forEach((tel) => {
          if (tel.tipo === "movil") {
            telefono_movil = tel.numero;
          } else if (tel.tipo === "fijo") {
            telefono = tel.numero;
          }
        });

        const domicilioReal = personaData.domicilios?.find(domicilio => domicilio.tipo === 'real') || null;
        const domicilioLegal = personaData.domicilios?.find(domicilio => domicilio.tipo === 'legal') || null;
        const domicilioSocial = personaData.domicilios?.find(domicilio => domicilio.tipo === 'social') || null;
        const domicilioEspecial = personaData.domicilios?.find(domicilio => domicilio.tipo === 'especial') || null;

        setItemCrudLocal((prevState) => ({
          ...prevState,
          id_actor: personaData.id || "",
          id_tipo_vinculo: "",
          tipo_persona_fisica: "juridica",
          numero_cuil: "",
          numero_documento: "",
          nombre: "",
          apellido: "",
          id_nacionalidad: "",
          persona_juridica: "true",
          email: personaData.email || "",
          telefono_movil: telefono_movil,
          telefono: telefono,
          razonSocial: personaData.razon_social || "",
          domicilioReal, // Asignar domicilioReal (null si no existe)
          domicilioLegal, // Asignar domicilioLegal (null si no existe)
          domicilioSocial, // Asignar domicilioSocial (null si no existe)
          domicilioEspecial,  // Asignar domicilioEspecial (null si no existe)

        }));

        setShowMessage(true);

        setMessage({
          severity: "success",
          messageText: "Datos encontrados",
        });

      } else {
        // Si personaData es null o vacío, inicializa itemCrudLocal 
        setItemCrudLocal((prevState) => ({
          ...prevState,
          cuit: "",
          razonsocial: "",
          domicilioLegal: null,
          domicilioSocial: null,
          domicilioEspecial: null,
          numero: "", // Mantener el número ingresado
        }));
        setShowMessage(true);
        setMessage({
          severity: "error",
          messageText: "No se encontraro datos para el Cuit solicitado",
        });
      }
    } catch (error) {
      console.error('Error al buscar persona por Cuit', error);
      setMessage({
        severity: "error",
        messageText: "Error al buscar datos por Cuit",
      });
    }
  };



  const handleSearchFisica = async () => {
    try {
      // Verifica si es CUIL o Documento
      let telefono_movil_fisica = "";
      let telefono_fisica = "";

      const searchData = itemCrudLocal.numero_cuil
        ? { cuil: itemCrudLocal.numero_cuil }
        : { numero: itemCrudLocal.numero, tipo_documento: "otro" };
      if (!searchData) {
        setMessage({
          severity: "warning",
          messageText: "Incorpore número de documento",
        });

        return;
      }


      const response = itemCrudLocal.numero_cuil
        ? await getPersonas({ numero_cuil: searchData.cuil })
        : await getPersonas({ numero_documento: searchData.numero, tipo_documento: "otro" });

      if (response.status === "exists") {

        const personaData = response.data;

        personaData.telefonos.forEach((tel) => {
          if (tel.tipo === "movil") {
            telefono_movil_fisica = tel.numero;
          } else if (tel.tipo === "fijo") {
            telefono_fisica = tel.numero;
          }
        });
        // Filtrar domicilios según su tipo
        const domicilioReal = personaData.domicilios?.find(domicilio => domicilio.tipo === 'real') || null;
        const domicilioLegal = personaData.domicilios?.find(domicilio => domicilio.tipo === 'legal') || null;
        const domicilioSocial = personaData.domicilios?.find(domicilio => domicilio.tipo === 'social') || null;
        const domicilioEspecial = personaData.domicilios?.find(domicilio => domicilio.tipo === 'especial') || null;
        setItemCrudLocal((prevState) => ({
          ...prevState,
          id_actor: personaData.id || "",
          persona_fisica: "true",
          tipo_persona_fisica: "fisica",
          nombre: personaData.nombre || "",
          apellido: personaData.apellido || "",
          id_nacionalidad: "",
          persona_juridica: "false",
          numero_cuit: "",
          razon_social: "",
          email: personaData.email || "",
          telefono_movil: telefono_movil_fisica,
          telefono: telefono_fisica,
          domicilioReal, // Asignar domicilioReal (null si no existe)
          domicilioLegal, // Asignar domicilioLegal (null si no existe)
          domicilioSocial, // Asignar domicilioSocial (null si no existe)
          domicilioEspecial,  // Asignar domicilioEspecial (null si no existe)
        }));

        setShowMessage(true);

        setMessage({
          severity: "success",
          messageText: "Datos encontrados",
        });

      } else {
        // Si personaData es null o vacío, inicializa itemCrudLocal 
        setItemCrudLocal((prevState) => ({
          ...prevState,
          id_actor: "",
          nombre: "",
          apellido: "",
          numero_cuil: "",
          email: "",

          domicilioReal: null,
          domicilioLegal: null,
          domicilioSocial: null,
          domicilioEspecial: null,
          numero: "", // Mantener el número ingresado
        }));
        setShowMessage(true);
        setMessage({
          severity: "error",
          messageText: "No se encontraro datos para el documento solicitado",
        });
      }
    } catch (error) {
      console.error('Error al buscar persona por documento', error);
      setMessage({
        severity: "error",
        messageText: "Error al buscar datos por documento",
      });
    }
  };

  const handleSave = () => {
    if (actionCrud === "view") return;
    const currentErrors = {};
    if (itemCrudLocal.nombre.trim() === "" && itemCrudLocal.tipoPersonActor === "Fisica") {
      currentErrors.nombre = "El nombre es obligatorio";
    }
    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      setMessage({
        severity: "error",
        messageText: "Por favor, completa todos los campos obligatorios",
      });
      setShowMessage(true);
      return;
    }
    setIsDoingSomething(true);
    setTimeout(() => {
      setIsDoingSomething(false);

      onSave(itemCrudLocal);
    }, 1000);

    setMessage({
      messageText: "Datos guardados exitosamente",
      severity: "success",
    });
    setShowMessage(true);
  };


  const handleDomicilioChange = (type, data) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [`domicilio${type.charAt(0).toUpperCase() + type.slice(1)}`]: data,
    });
  };

  return (
    <Dialog
      open={true}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      aria-labelledby="form-dialog-title"
    >
      {isDoingSomething && <LinearProgress />}
      <DialogTitle id="form-dialog-title">
        {actionCrud === "create"
          ? `Agregar ${nameItemText}`
          : actionCrud === "view"
            ? `Ver ${nameItemText}`
            : `Modificar ${nameItemText}`}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} >
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.vinculo}>
              <Autocomplete
                id="vinculo"
                size="small"
                name="vinculo"
                value={materiavinculoOptions.find(option => option.value === itemCrudLocal.materiavinculoValue
                ) || null
                }
                onChange={(event, newValue) => {
                  setMateriaVinculoValue(newValue ? newValue.value : null);
                  setItemCrudLocal(prev => ({
                    ...prev,
                    id_tipo_vinculo: newValue ? newValue.id : "sin datdos"  // Actualiza correctamente `id_tipo_vinculo`
                  }));
                }}



                options={materiavinculoOptions}
                noOptionsText="No hay opciones disponibles"
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Vinculo*" />
                )}
                isOptionEqualToValue={(option, value) => option.value === value}
                disabled={!!itemCrudLocal.vinculo || actionCrud === "view"}
              />

              <FormHelperText>{errors.vinculo || "Seleccione vinculo"}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6} >


            <FormControl fullWidth error={!!errors.tipoPersonActor}>


              <Autocomplete
                id="tipoPersonAutocomplete"
                size="small"
                value={tipoPersonaOptions.find(option => option.value === itemCrudLocal.tipoPersonActor) || null}
                onChange={(event, newValue) => handleChange(event, newValue, "tipoPersonActor")}
                options={tipoPersonaOptions}
                noOptionsText="No hay opciones disponibles"
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                renderInput={(params) => (
                  <TextField {...params} label="Tipo dssse Persona" variant="outlined" />
                )}
              />

              <FormHelperText>{errors.tipoPersonActor || "Seleccione tipo de persona"}</FormHelperText>
            </FormControl>
          </Grid>



          {itemCrudLocal.tipoPersonActor === "Fisica" && (
            <>
              <Grid item sm={4}>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedNacionalidad.argentino}
                        onChange={handleNacionalidadChange}
                        name="argentino"
                        selec
                      />
                    }
                    label="Argentino"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedNacionalidad.extranjero}
                        onChange={handleNacionalidadChange}
                        name="extranjero"
                      />
                    }
                    label="Extranjero"
                  />
                </FormGroup>

              </Grid>

              {/* Mostrar CUIL si es Argentino */}
              {selectedNacionalidad.argentino && (
                <>
                  <Grid item xs={12} sm={5}>

                    <TextField

                      size="small"
                      label="Búsqueda por Cuil"
                      id="numero_cuil"
                      name="numero_cuil"

                      value={itemCrudLocal.numero_cuil || ''}
                      onChange={handleChange}
                      placeholder="busqueda por CUIL solo numeros"
                      required
                      error={!!errors.numero_cuil}
                      helperText={errors.numero_cuil}
                      disabled={!!itemCrudLocal.numero || actionCrud === "view"}

                      fullWidth
                      InputProps={{
                        // Lupa (Search) debe estar como startAdornment, antes del texto

                        startAdornment: (
                          <InputAdornment position="start">
                            <Tooltip title="Buscar persona">
                              <IconButton onClick={handleSearchFisica} color="primary">
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>

                          </InputAdornment>
                        ), endAdornment: (
                          <InputAdornment position="end">

                            {itemCrudLocal.numero_cuil && (
                              <Tooltip title="Resetear búsqueda">
                                <IconButton onClick={handleReset} color="warning">
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />


                  </Grid>


                </>

              )}

              {selectedNacionalidad.extranjero && (
                <>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Búsqueda por nro de documento"
                      id="numero"
                      name="numero"
                      value={itemCrudLocal.numero || ''}
                      onChange={handleChange}
                      placeholder="Buscar..."
                      required
                      error={!!errors.numero}
                      helperText={errors.numero}
                      disabled={!!itemCrudLocal.nombre || actionCrud === "view"}
                      fullWidth
                      InputProps={{
                        // Lupa (Search) debe estar como startAdornment, antes del texto
                        startAdornment: (
                          <InputAdornment position="start">
                            <Tooltip title="Buscar persona">
                              <IconButton onClick={handleSearchFisica} color="primary">
                                <SearchIcon />
                              </IconButton>
                            </Tooltip>

                          </InputAdornment>
                        ), endAdornment: (
                          <InputAdornment position="end">

                            {itemCrudLocal.numero && (
                              <Tooltip title="Resetear búsqueda">
                                <IconButton onClick={handleReset} color="warning">
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    /> </Grid>
                </>

              )}




              {/* Fila 2: Nombre */}
              <Grid item sm={4}>
                <TextField
                  label="Nombre"
                  name="nombre"
                  value={itemCrudLocal.nombre}
                  onChange={handleChange}
                  placeholder="Ingrese nombre completo"
                  size="small"
                  fullWidth
                  required
                  error={!!errors.nombre}
                  helperText={errors.nombre || ""}
                  disabled={!!itemCrudLocal.numero || actionCrud === "view"} // Deshabilitar si tiene datos o está en modo "view"
                />
              </Grid>


              <Grid item xs={12} sm={4}>
                <TextField
                  label="Apellido"
                  name="apellido"
                  value={itemCrudLocal.apellido}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  required
                  placeholder="Ingrese apellido"
                  error={!!errors.apellido}
                  helperText={errors.numero_cuil || ""}
                  disabled={actionCrud === "view"}

                />
              </Grid>

              {/* Fila 3: Correo Electrónico */}
              <Grid item xs={6}>
                <TextField
                  label="Correo Electrónico"
                  name="email"
                  value={itemCrudLocal.email}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  required
                  placeholder="Correo electrónico"
                  error={!!errors.email}
                  helperText={errors.email || ""}
                  disabled={!!itemCrudLocal.email || actionCrud === "view"} // Deshabilitar si tiene datos o está en modo "view"
                />
              </Grid>


              {/* Acordeón para domicilios */}
              <Grid item xs={12}>
                <Accordion sx={{
                  boxShadow: 'none', // Eliminar sombra
                  border: 'none', // Eliminar borde
                  borderBottom: '2px solid', // Agregar un borde inferior
                  borderColor: 'acordeon.main', // Color del borde (puedes cambiarlo según tus necesidades)
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {itemCrudLocal.domicilioReal && Object.keys(itemCrudLocal.domicilioReal).length > 0 ? (
                        <>
                          Domicilio REAL{" "}
                          <Typography component="span" color="primary">: Domicilio encontrado</Typography>
                        </>
                      ) : (
                        <>
                          Domicilio REAL{" "}
                          <Typography component="span" color="primary">: Agregar nuevo</Typography>
                        </>
                      )}
                    </Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Domicilio
                      data={itemCrudLocal.domicilioReal && Object.keys(itemCrudLocal.domicilioReal).length > 0
                        ? itemCrudLocal.domicilioReal
                        : itemCrudLocal.domicilios}
                      onChange={(data) => handleDomicilioChange('real', data)}
                    />
                  </AccordionDetails>
                </Accordion>

              </Grid>
              <Grid item xs={12}>
                <Accordion sx={{
                  boxShadow: 'none', // Eliminar sombra
                  border: 'none', // Eliminar borde
                  borderBottom: '2px solid', // Agregar un borde inferior
                  borderColor: 'acordeon.main', // Color del borde (puedes cambiarlo según tus necesidades)
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >

                    <Typography>
                      {itemCrudLocal.domicilioLegal && Object.keys(itemCrudLocal.domicilioLegal).length > 0 ? (
                        <>
                          Domicilio LEGAL{" "}
                          <Typography component="span" color="primary">: Datos encontrados</Typography>
                        </>
                      ) : (
                        <>
                          Domicilio LEGAL{" "}
                          <Typography component="span" color="primary">: Agregar nuevo</Typography>
                        </>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Domicilio
                      data={itemCrudLocal.domicilioLegal && Object.keys(itemCrudLocal.domicilioLegal).length > 0
                        ? itemCrudLocal.domicilioLegal
                        : itemCrudLocal.domicilios}
                      onChange={(data) => handleDomicilioChange('legal', data)}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion sx={{
                  boxShadow: 'none', // Eliminar sombra
                  border: 'none', // Eliminar borde
                  borderBottom: '2px solid', // Agregar un borde inferior
                  borderColor: 'acordeon.main', // Color del borde (puedes cambiarlo según tus necesidades)
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >

                    <Typography>
                      {itemCrudLocal.domicilioEspecial && Object.keys(itemCrudLocal.domicilioEspecial).length > 0 ? (
                        <>
                          Domicilio ESPECIAL{" "}
                          <Typography component="span" color="primary">: Datos encontrados</Typography>
                        </>
                      ) : (
                        <>
                          Domicilio ESPECIAL{" "}
                          <Typography component="span" color="primary">: Agregar nuevo</Typography>
                        </>
                      )}
                    </Typography>



                  </AccordionSummary>
                  <AccordionDetails>
                    <Domicilio
                      data={itemCrudLocal.domicilioEspecial && Object.keys(itemCrudLocal.domicilioEspecial).length > 0
                        ? itemCrudLocal.domicilioEspecial
                        : itemCrudLocal.domicilios}
                      onChange={(data) => handleDomicilioChange('especial', data)}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

            </>
          )}

          {itemCrudLocal.tipoPersonActor === "Juridica" && (
            <>



              <Grid item xs={12} sm={5}>

                <TextField
                  variant="outlined"
                  size="small"
                  label="Búsqueda por Cuit"
                  id="cuit"
                  name="cuit"
                  value={itemCrudLocal.cuit || ''}
                  onChange={handleChange}
                  placeholder="ingrese CUIT"
                  required
                  error={!!errors.cuit}
                  helperText={errors.cuit}
                  disabled={!!itemCrudLocal.razonSocial || actionCrud === "view"}
                  fullWidth
                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="end">{itemCrudLocal.cuit && (
                        <Tooltip title="Resetear búsqueda">
                          <IconButton onClick={handleResetJuridica} color="error">
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                        <Tooltip title="Buscar persona">
                          <IconButton onClick={handleSearchJuridica} color="primary">
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>

                      </InputAdornment>
                    ),
                  }}
                />


              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Razón Social"
                  name="razonSocial"
                  value={itemCrudLocal.razonSocial}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  required
                  placeholder="Razón Social"
                  error={!!errors.razonSocial}
                  helperText={errors.razonSocial || ""}
                  disabled={actionCrud === "view"}
                />
              </Grid>

              {/* Acordeón para domicilios */}
              <Grid item xs={12}>
                <Accordion sx={{
                  boxShadow: 'none', // Eliminar sombra
                  border: 'none', // Eliminar borde
                  borderBottom: '2px solid', // Agregar un borde inferior
                  borderColor: 'acordeon.main', // Color del borde (puedes cambiarlo según tus necesidades)
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >

                    <Typography>
                      {itemCrudLocal.domicilioSocial && Object.keys(itemCrudLocal.domicilioSocial).length > 0 ? (

                        <>
                          Domicilio SOCIAL{" "}
                          <Typography component="span" color="primary">: Datos encontrados</Typography>
                        </>
                      ) : (
                        <>
                          Domicilio SOCIAL{" "}
                          <Typography component="span" color="primary">: Agregar nuevo</Typography>
                        </>
                      )}
                    </Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Domicilio
                      data={itemCrudLocal.domicilioSocial && Object.keys(itemCrudLocal.domicilioSocial).length > 0
                        ? itemCrudLocal.domicilioSocial
                        : itemCrudLocal.domicilios}
                      onChange={(data) => handleDomicilioChange('social', data)}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* Acordeón para domicilios */}
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >

                    <Typography>
                      {itemCrudLocal.domicilioLegal && Object.keys(itemCrudLocal.domicilioLegal).length > 0 ? (

                        <>
                          Domicilio LEGAL{" "}
                          <Typography component="span" color="primary">: Datos encontrados</Typography>
                        </>
                      ) : (
                        <>
                          Domicilio LEGAL{" "}
                          <Typography component="span" color="primary">: Agregar nuevo</Typography>
                        </>
                      )}
                    </Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Domicilio
                      data={itemCrudLocal.domicilioLegal && Object.keys(itemCrudLocal.domicilioLegal).length > 0
                        ? itemCrudLocal.domicilioLegal
                        : itemCrudLocal.domicilios}
                      onChange={(data) => handleDomicilioChange('legal', data)}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* Puedes agregar más acordeones para otros tipos de domicilio */}


              <Grid item xs={12}>
                <Accordion sx={{
                  boxShadow: 'none', // Eliminar sombra
                  border: 'none', // Eliminar borde
                  borderBottom: '2px solid', // Agregar un borde inferior
                  borderColor: 'secondary.main', // Color del borde (puedes cambiarlo según tus necesidades)
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >


                    <Typography>
                      {itemCrudLocal.domicilioEspecial && Object.keys(itemCrudLocal.domicilioEspecial).length > 0 ? (
                        <>
                          Domicilio ESPECIAL{" "}
                          <Typography component="span" color="secondary">: Datos encontrados</Typography>
                        </>
                      ) : (
                        <>
                          Domicilio ESPECIAL{" "}
                          <Typography component="span" color="primary">: Agregar nuevo</Typography>
                        </>
                      )}
                    </Typography>

                  </AccordionSummary>
                  <Domicilio
                    data={itemCrudLocal.domicilioEspecial && Object.keys(itemCrudLocal.domicilioEspecial).length > 0
                      ? itemCrudLocal.domicilioEspecial
                      : itemCrudLocal.domicilios}
                    onChange={(data) => handleDomicilioChange('especial', data)}
                  />
                </Accordion>
              </Grid>
            </>

          )}
        </Grid>
      </DialogContent>
      {actionCrud !== "view" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            pt: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Cancelar sin guardar los cambios">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={handleCloseDialog}
                aria-label="Cancelar"
              >
                Cancelar
              </Button>
            </Tooltip>

          </Box>
          <Tooltip title="Guardar la información ingresada">
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
              disabled={isDoingSomething || Object.keys(errors).length > 0}
              aria-label="Guardar"
            >
              {isDoingSomething
                ? "Guardando..."
                : actionCrud === "create"
                  ? "Agregar"
                  : "Actualizar"}
            </Button>
          </Tooltip>
        </Box>

      )}  <Message
        open={showMessage}
        message={message.messageText}
        severity={message.severity}
        onClose={() => setShowMessage(false)}
      />
    </Dialog>
  );
};

export default ActorDialog;
