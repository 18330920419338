import { urlBackendUsuarios } from "../config";
import HttpService from "./HttpService";

const epName = "/all_info/";

export const getAllinfo = async (params) => {
  const _errorMessage = `Se produjo un error al obtener la información`;
  const client = HttpService.getAxiosClient();
  HttpService.configure();
  try {
    const token = localStorage.getItem('Token');
    if (!token) {
      console.error('No se encontró token en localStorage');
      return { ok: false, messageText: 'No se encontró token en localStorage', messageSeverity: 'error' };
    }



    const response = await client.get(`${urlBackendUsuarios}${epName}`, {
      params: params,

    });

    if (response.status === 200) {
      if (!("error_code" in response.data)) {
        response.ok = true;
        // Aquí puedes guardar los datos en localStorage si es necesario
        // localStorage.setItem("infoData", JSON.stringify(response.data));
        return response;
      } else {
        // Si hay un error controlado en la respuesta
        response.ok = false;
        response.messageText = response.data.error_message; // Ajusta esto según la estructura de tu API
        response.messageSeverity = "error";
        return response;
      }
    } else {
      // Si la respuesta no es 200 OK
      response.ok = false;
      response.messageText = _errorMessage;
      response.messageSeverity = "error";
      return response;
    }
  } catch (error) {
    console.error(_errorMessage);
    if (error.response) {
      console.error('Respuesta de error:', error.response.data);
    } else if (error.request) {
      console.error('Solicitud no respondida:', error.request);
    } else {
      console.error('Error general:', error.message);
    }

    // Manejo genérico de errores
    return { ok: false, messageText: _errorMessage, messageSeverity: 'error' };
  }
};
