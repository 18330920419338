import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { MatxTheme } from "./components";
import SettingsProvider from "./core/contexts/SettingsContext";
import { Box } from "@mui/material";

import routes from "./project/routes";
import { useState, useEffect } from 'react';
import keycloakObject from "./project/utils/keycloak";
import { getUsuarios } from './project/services/UsuarioService';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { getAllinfo } from "./project/services/AllInfo";

export default function App() {
	const content = useRoutes(routes);
	const [initialized, setInitialized] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const [permisos, setPermisos] = useState({});
	const [isLoading, setIsLoading] = useState(true); // Estado para controlar el estado de carga  


	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'purple' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	  }));
	useEffect(() => {
		const initKeycloak = async () => {
			const auth = await keycloakObject.initKeycloak();
			setAuthenticated(auth);
			setInitialized(true);
		};
		initKeycloak();
	}, []);

	useEffect(() => {
		const saveTokenToLocalStorage = () => {
			const token = keycloakObject.getToken();
			if (token) {
				localStorage.setItem('Token', token);
			}
		};

		if (authenticated) {
			saveTokenToLocalStorage();
		}
	}, [authenticated]);

	useEffect(() => {
		const fetchUsuario = async () => {
			let _username = localStorage.getItem("UserName");

			if (localStorage.getItem("UserId") && localStorage.getItem("emailUsuario") &&
				localStorage.getItem("nombreUsuario") && localStorage.getItem("apellidoUsuario")) {
				localStorage.setItem("autorizaZion", true);
				return;
			}

			if (!_username) {
				localStorage.setItem("autorizaZion", false);
				return;
			}

			console.log('usuario-app useEffectUsuario: ', _username);

			try {
				const response = await getUsuarios({ username: _username });

				if (response.ok) {
					const userData = response.data.data[0];
					localStorage.setItem("UserId", userData.id);
					localStorage.setItem("emailUsuario", userData.email);
					localStorage.setItem("nombreUsuario", userData.nombre);
					localStorage.setItem("apellidoUsuario", userData.apellido);
					localStorage.setItem("autorizaZion", true);
					localStorage.setItem("Token", token);

				} else {
					localStorage.setItem("autorizaZion", false);
				}
			} catch (error) {
				console.error('Error al obtener datos del usuario:', error);
				localStorage.setItem("autorizaZion", false);
			}
		};

		if (authenticated) {
			fetchUsuario();
		}
	}, [authenticated]);


	useEffect(() => {
		const fetchPermisos = async () => {
			const token = keycloakObject.getToken();
			if (!token) {
				console.log('No se encontró token, no se puede obtener permisos.');
				setIsLoading(false); // Asegurarse de actualizar el estado de carga 
				return;
			}

			const _username = localStorage.getItem('UserName');
			const _desc_sistema = 'mesa-sistema-expedientes'; 

			try {
				const response = await getAllinfo({ desc_sistema: _desc_sistema, username: _username });
				if (response.ok) {
					const permisosData = response.data.lista_roles_cus.reduce((acc, role) => {
						role.casos_de_uso.forEach(caso => {
							acc[caso.id_sistema_cu] = caso;
						});
						return acc;
					}, {});

					// Guardar permisos en localStorage
					localStorage.setItem("permisos", JSON.stringify(permisosData));

					setPermisos(permisosData);
				}
			} catch (error) {
				console.error('Error fetching permisos:', error);
			} finally {
				setIsLoading(false); // Asegurarse de actualizar el estado de carga
			}
		};

		if (authenticated && initialized) {
			fetchPermisos();
		}
	}, [authenticated, initialized]);

	// Renderiza la aplicación
	return (
		<SettingsProvider>
			<MatxTheme>

				
				<CssBaseline />
				{isLoading ? (
					<Box sx={{ margin: '15px' }}>
						<p>Cargando...</p>
					</Box>
				) : (content
					
				)}
			</MatxTheme>
		</SettingsProvider>
	);

	// Sin Keycloak:-->
	// return (
	// 	<SettingsProvider>
	// 		<MatxTheme>
	// 			{content}
	// 		</MatxTheme>
	// 	</SettingsProvider>
	// );
}
