import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Autocomplete,
  FormControl,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import { getFuero } from "../../../services/FueroService.js";
import { getOrganismo } from "../../../services/OrganismoService.js";

const initialState = {
  fuero: "",
  cj: "",
  organismo: "",
  numero: "",
  caratula: "",
};  

const RelacionadoDialog = ({
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [fueroOptions, setFueroOptions] = useState([]);
  const [circunscripcionValue, setCircunscripcionValue] = useState(null);
  const [organismoOptions, setOrganismoOptions] = useState([]);
  
  const circunscripcionOptions = [
    { cj: 1, label: 'Primera Circunscripción' },
    { cj: 2, label: 'Segunda Circunscripción' },
    { cj: 3, label: 'Tercera Circunscripción' },
    { cj: 4, label: 'Cuarta Circunscripción' },
  ];

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const fetchedFueroOptions = await getFuero();
        setFueroOptions(fetchedFueroOptions);
        setCircunscripcionValue(circunscripcionOptions[0]);
      } catch (error) {
        console.error('Error de Inicio de datos ', error);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchOrganismo = async () => {
      if (itemCrudLocal.fuero && circunscripcionValue) {
        try {
          const options = await getOrganismo({
            id_fuero: itemCrudLocal.fuero.id,
            circunscripcion_judicial: circunscripcionValue.cj,
          });
          setOrganismoOptions(options);
        } catch (error) {
          console.error('Error fetching organismo', error);
        }
      }
    };
    fetchOrganismo();
  }, [itemCrudLocal.fuero, circunscripcionValue]);

  useEffect(() => {
    if (actionCrud === "create") {
      setItemCrudLocal(initialState);
    } else {
      setItemCrudLocal(itemCrud);
    }
  }, [actionCrud, itemCrud]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemCrudLocal(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleSave = () => {
    if (actionCrud === "view") return;

    const currentErrors = {};
    if (!itemCrudLocal.numero) currentErrors.numero = "El número de expediente es obligatorio";
    if (!itemCrudLocal.caratula) currentErrors.caratula = "La carátula es obligatoria";

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      setMessage({ severity: "error", messageText: "Por favor, completa todos los campos obligatorios" });
      setShowMessage(true);
      return;
    }

    setIsDoingSomething(true);
    setTimeout(() => {
      setIsDoingSomething(false);
      onSave(itemCrudLocal);
      setMessage({ messageText: "Datos guardados exitosamente", severity: "success" });
      setShowMessage(true);
    }, 1000);
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth scroll="paper" aria-labelledby="form-dialog-title">
      {isDoingSomething && <LinearProgress />}
      <DialogTitle id="form-dialog-title">
        {actionCrud === "create" ? `Agregar Relacionado` : actionCrud === "view" ? `Ver Relacionado` : `Modificar Relacionado`}
        <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8, color: '#53522C' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.fuero}>
            
              <Autocomplete
              id="fuero"
                size="small"
          options={fueroOptions}
          value={itemCrudLocal.fuero}
          onChange={(event, newValue) => 
            setItemCrudLocal(prev => ({
              ...prev,
              id_fuero: newValue?.id || "",
              fuero: newValue?.label || ""
            }))
          }
          renderInput={(params) => <TextField {...params} label="Fuero" />}
        />

              <FormHelperText>{errors.fuero || "Seleccione fuero"}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.cj}>
            
              <Autocomplete
                 id="cj"
                 size="small"
                 value={circunscripcionValue}
          options={circunscripcionOptions}
          onChange={(event, newValue) => 
            setItemCrudLocal(prev => ({
              ...prev,
              id_cj: newValue?.cj || "",
              cj: newValue?.label || ""
            }))
          }
          renderInput={(params) => <TextField {...params} label="Circunscripción Judicial" />}
        />
              <FormHelperText>{errors.cj || "Seleccione cssircunscripción"}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.organismo}>
            
              <Autocomplete
                id="organismo"
                size="small"
                value={itemCrudLocal.organismo}
          options={organismoOptions}
          onChange={(event, newValue) => 
            setItemCrudLocal(prev => ({
              ...prev,
              id_organismo: newValue?.id || "",
              organismo: newValue?.label || ""
            }))
          }
          renderInput={(params) => <TextField {...params} label="Organismo" />}
        />
              <FormHelperText>{errors.organismo || "Seleccione organismo"}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Número de Expediente"
              name="numero"
              size="small"
              value={itemCrudLocal.numero}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.numero}
              helperText={errors.numero || "Ingrese el número de expediente"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Carátula"
              name="caratula"
              size="small"
              value={itemCrudLocal.caratula}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.caratula}
              helperText={errors.caratula || "Ingrese la carátula"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {actionCrud !== "view" && (
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Button variant="outlined" color="primary" startIcon={<CloseIcon />} onClick={onClose}>Cancelar</Button>
          <Tooltip title="Guardar la información ingresada">
            <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSave} disabled={isDoingSomething}>
              {isDoingSomething ? "Guardando..." : actionCrud === "create" ? "Agregar" : "Actualizar"}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Dialog>
  );
};

export default RelacionadoDialog;
