import MUIDataTable from "mui-datatables";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import DialogExpediente from "./TableDialogExpediente";
import DialogNuevaDemanda from "./DialogNuevaDemanda";
import { Box, Button, Stack } from "@mui/material";
import { Breadcrumb } from "../../components";

export default function TableExpediente() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDemanda, setOpenDialogDemanda] = useState(false); // Estado para DialogNuevaDemanda
  const [selectedItem, setSelectedItem] = useState(null);
  const [actionCrud, setActionCrud] = useState(""); // Puede ser "create", "update", "read", etc.

  const abrirEnNuevaVentana = (url) => {
    const width = 800;
    const height = 600;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);

    window.open(url, 'nuevaVentana', `width=${width},height=${height},top=${top},left=${left}`);
  };

  const abrirDialogo = (item, action) => {
    setSelectedItem(item);
    setActionCrud(action);
    setOpenDialog(true);
  };

  const abrirDialogoDemanda = (action) => {
    setActionCrud(action);
    setOpenDialogDemanda(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  const handleDialogDemandaClose = () => {
    setOpenDialogDemanda(false);
  };

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {},
    },
    {
      name: "tribunal",
      label: "Tribunal",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "fecha_entrada",
      label: "Fecha Lista",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "caratula",
      label: "Carátula",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "numero",
      label: "Número",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "tools",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => (
          <>
            <Tooltip title="Diálogo">
              <IconButton onClick={() => abrirDialogo(datos[dataIndex], "update")}>
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver Ventana Flotante ">
              <IconButton onClick={() => abrirEnNuevaVentana(`/informacion/${datos[dataIndex].id}`)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver Ventana">
              <IconButton component={Link} to={`/informacion/${datos[dataIndex].id}`} target="_blank" rel="noopener noreferrer">
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  const datos = [
    {
      id: "111111",
      tribunal: "1GA3",
      fecha_entrada: "18 JUNIO, 2019",
      caratula: "PEREZ C/ SANCOR SEGUROS P/ DYP",
      estado: "activo",
      numero: "13-07515672-8(319615)",
    },
    {
      id: "222222",
      tribunal: "1GA4",
      fecha_entrada: "10 MAYO, 2019",
      caratula: "RAMIREZ C/ ILLANEZ P/ DIV",
      estado: "activo",
      numero: "15-07515672-8(319615)",
    },
    {
      id: "33333",
      tribunal: "1GA3",
      fecha_entrada: "10 ABRIL, 2019",
      caratula: "RAMIREZ ENRIQUE P/ BLSG",
      estado: "activo",
      numero: "17-07515672-8(319615)",
    },
    {
      id: "44444",
      tribunal: "1GA5",
      fecha_entrada: "8 MARZO, 2019",
      caratula: "RODRIGUEZ IGNACIO P/ SUC",
      estado: "activo",
      numero: "13-07515672-8(319615)",
    },
    {
      id: "55555",
      tribunal: "1GA6",
      fecha_entrada: "1 FEBRERO, 2019",
      caratula: "ZANOTTI JUAN P/ MED. PREC",
      estado: "archivado",
      numero: "13-07515672-8(319615)",
    },
    {
      id: "66666",
      tribunal: "1GA7",
      fecha_entrada: "1 ENERO, 2019",
      caratula: "",
      estado: "activo",
      numero: "13-07515672-8(319615)",
    },
  ];


  return (
    <>
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "Tablas", path: "/project/mdtable/MdTable" }, { name: "MdTable" }]} />
        </Box>
     
 <Stack direction="row" spacing={2} mb={2}> 
          <Button variant="contained" color="secondary"onClick={() => abrirEnNuevaVentana(`/project/tableExpedientes/formDemandas`)}>
            Ingresar Demanda en Ventana
          </Button>

          <Button variant="contained" color="secondary" onClick={() => abrirDialogoDemanda("create")}>
            Ingresar Demanda en Dialogo
          </Button>




          <Button variant="contained" color="secondary" onClick={() => abrirDialogoDemanda("create")}>
            Ingresar Escrito
          </Button>
        </Stack>
        <MUIDataTable title="Expedientes" columns={tableColumns} data={datos} />
      </Container>
      {openDialog && selectedItem && (
        <DialogExpediente
          onSave={handleDialogClose}
          onClose={handleDialogClose}
          itemCrud={selectedItem}
          actionCrud={actionCrud}
          setMessage={(msg) => console.log(msg)}
          setShowMessage={(show) => console.log(show)}
        />
      )}
      {openDialogDemanda && (
        <DialogNuevaDemanda
          onSave={handleDialogDemandaClose}
          onClose={handleDialogDemandaClose}
          itemCrud={selectedItem}
          actionCrud={actionCrud}
          setMessage={(msg) => console.log(msg)}
          setShowMessage={(show) => console.log(show)}
        />
      )}
    </>
  );
}
