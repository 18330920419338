import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  IconButton,
  LinearProgress,
  Box,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MUIDataTable from "mui-datatables";
import Message from "../../../utils/Message";
import ActorDialog from "./ActorDialog";
import ConfirmDialog from "../ConfirmDialog";

const initialState = {
  nombre: "",
  apellido: "",
  cuit: "",
  numero_cuil:"",
  vinculo:"",

};

const ActorTable = ({ actor, setActor, id_materia, id_fuero }) => {
  const [itemsCrud, setItemsCrud] = useState(actor);
  const [itemCrud, setItemCrud] = useState(initialState);
  const [actionCrud, setActionCrud] = useState("");
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
console.log(actor);

  useEffect(() => {
    // Inicializa itemsCrud con actor
    setItemsCrud(actor);
  }, [actor]);

  useEffect(() => {
    // Actualiza actor cuando itemsCrud cambia
    setActor(itemsCrud);
  }, [itemsCrud, setActor]);

  const handleOpenConfirmDialog = (itemCrud) => {
    setItemToDelete(itemCrud);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      const updatedItems = itemsCrud.filter((item) => item.id !== itemToDelete.id);
      setItemsCrud(updatedItems);
      setMessage({
        severity: "error",
        messageText: "Ítem eliminado exitosamente",
      });
      setShowMessage(true);
    }
    handleCloseConfirmDialog();
  };

  const handleDelete = (itemCrud) => {
    handleOpenConfirmDialog(itemCrud);
  };

  const handleClickActionCrud = (itemCrud = {}, action) => {
    setActionCrud(action);
    setItemCrud(action !== "create" ? { ...itemCrud } : initialState);
    setOpenDialog(true);
  };

  const handleDialogSave = (updatedItem) => {
    if (actionCrud === "create") {
      const newItem = { ...updatedItem, id: Date.now() };
      const newItemsCrud = [...itemsCrud, newItem];
      setItemsCrud(newItemsCrud);
    } else if (actionCrud === "update") {
      const updatedItems = itemsCrud.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      );
      setItemsCrud(updatedItems);
    }
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const tableColumns = [
 
    { name: "tipoPersonActor", label: "Tipo Persona", options: { filter: false, sort: false } },
   /* { name: "busquedacuil", label: "Cuil/Cuit", options: { filter: false, sort: false } },
    { name: "cuit", label: "Cuil/Cuit", options: { filter: false, sort: false } },*/
    {
        name: "razonSocialNombreApellido",
        label: "Identificación",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
          
            const rowData = tableMeta.rowData;
             // Obteniendo la fila completa
               console.log("table",tableMeta.rowData);
            const razonSocial = rowData[6]; // Índice para 'razonSocial'
            const nombre = rowData[2]; // Índice para 'nombre'
            const apellido = rowData[3]; // Índice para 'apellido'
            
            // Combinar los valores y mostrarlos
            if (razonSocial) {
              return razonSocial; // Si existe razonSocial, mostrarlo
            } else if (nombre && apellido) {
              return `${nombre} ${apellido}`; // Si no existe razonSocial, mostrar nombre y apellido
            } else {
              return ''; // Si ninguno existe, retornar un string vacío
            }
          },
        },
      },
  { name: "nombre", label: "Nombre", options: { filter: false, sort: false ,  display: false} },
    { name: "apellido", label: "Apellido", options: { filter: false, sort: false , display: false} },
    { name: "numero_cuil", label: "Cuil", options: { filter: false, sort: false ,  display: false} },
    { name: "cuit", label: "cuit", options: { filter: false, sort: false , display: false} },
    { name: "razonSocial", label: "Razon", options: { filter: false, sort: false , display: false} },
    {
        name: "Cuil-Cuit",
        label: "Cuit-Cuil",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
          
            const rowData = tableMeta.rowData;
             // Obteniendo la fila completa
               console.log("table cuil-cuit",tableMeta.rowData);
            const cuil = rowData[4]; 
            const cuit = rowData[5]; 
          if (cuil) {
              return cuil; // Si existe razonSocial, mostrarlo
              } else {
              return cuit;
            }
          },
        },
      },
   
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const item = itemsCrud[dataIndex];
          return (
            <>
              <Tooltip title="Ver">
                <IconButton size="small" aria-label="view" onClick={() => handleClickActionCrud(item, "view")}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton size="small" aria-label="update" onClick={() => handleClickActionCrud(item, "update")}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton size="small" aria-label="delete" onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const tableOptions = {
    rowsPerPageOptions: [],
    serverSide: false,
    count: itemsCrud.length,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setCurrentPage,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 0,
    pagination: false,
    textLabels: {
      body: {
        noMatch: itemsCrud.length === 0 ? "No hay datos para mostrar" : "",
      },
    },
  };

  return (
    <Paper sx={{ width: "100%", mb: 1, border: 'none', boxShadow: 'none' }}>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleConfirmDelete}
        message={`¿Estás seguro de que quieres eliminar el Registro?`}
      />

      {isDoingSomething && <LinearProgress />}
      <Grid container spacing={0} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleClickActionCrud({}, "create")}
            sx={{ mb: 2 }}
          >
            Agregar Actores
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ p: 0, textAlign: 'left' }}>
        
          <MUIDataTable
            title={""}
            data={itemsCrud}
            columns={tableColumns}
            options={tableOptions}
          />
       
      </Box>
      {openDialog && (
        <ActorDialog
          open={openDialog}
          onClose={handleDialogClose}
          onSave={handleDialogSave}
          itemCrud={itemCrud}
          actionCrud={actionCrud}
          message={"asd"}
          showMessage={false}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
          id_fuero ={id_fuero}
          id_materia={id_materia}
        />
      )}
      <Message
        open={showMessage}
        message={message.messageText}
        severity={message.severity}
        onClose={() => setShowMessage(false)}
      />
    </Paper>
  );
};

export default ActorTable;
