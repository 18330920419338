import axios from "axios";
import { urlPortalBakend, epMateria } from "../config";
const token = localStorage.getItem("Token");
export const getMateria = async (params) => {
console.log(params);
  let _errorMessage = "Se produjo un error al obtener el Organismo"; 
  try {
    const response = await axios({
       method: "GET",
       url: `${urlPortalBakend}${epMateria}`,
      params:  params ,
      headers: {
		'Authorization': `Bearer ${token}`
	},
    });
    if (response.status === 200) {
        let _error = "error_code" in response.data;
        if (!_error) {
         
            return response.data.data.map(item => ({
                id: item.id,
      label: item.descripcion,
      solicita_monto: item.solicita_monto,
              }));
        }
    }
   
    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";t
    return response;
    
  } catch (error) { 
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};