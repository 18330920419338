
import { urlPortalBakend, epFuero } from "../config";
import HttpService from "./HttpService";
HttpService.configure();
const getAxiosClient = HttpService.getAxiosClient();

const itemGender = "M";
export const getFuero = async () => {
  let _errorMessage = "Se produjo un error al obtener el fuero";
  try {
    // Asegúrate de usar 'await' para esperar la respuesta asíncrona
    const response = await getAxiosClient.get(`${urlPortalBakend}${epFuero}`, 
     { headers: {
        "Access-Control-Allow-Origin": "*",
      }},
    );
    
    // Verifica que la respuesta tenga un status 200
    if (response.status === 200) {
      const _error = "error_code" in response.data;
      
      if (!_error) {
        // Si no hay error controlado, mapeamos los datos
        return response.data.data.map(item => ({
          id: item.id,
          label: item.descripcion,
        }));
      } else {
        // Si existe un error controlado
        return {
          ok: false,
          messageText: _errorMessage,
          messageSeverity: "error",
        };
      }
    }
    
    // Si no es un código de estado 200
    return {
      ok: false,
      messageText: _errorMessage,
      messageSeverity: "error",
    };

  } catch (error) {
    console.log(_errorMessage);
    
    // Manejo de errores más detallado
    if (error.response) {
      console.log("Error de respuesta:", error.response);
    } else if (error.request) {
      console.log("Error de solicitud:", error.request);
    } else {
      console.log("Error desconocido:", error);
    }
    
    return {
      ok: false,
      messageText: _errorMessage,
      messageSeverity: "error",
    };
  }
};

		
