

import axios from "axios";
import HttpService from "./HttpService";

import { urlBackendPersonas,epPersonasCuit,epPersonasDoc} from "../config";

HttpService.configure();
const getAxiosClient = HttpService.getAxiosClient();
const itemGender = "M";


export const getTipoPersona = async () => {
  try {
    const tipoPersonaOptions = [
      { label: "Persona Física", value: "Fisica" },
      { label: "Persona Jurídica", value: "Juridica" }
    ];
    
    return tipoPersonaOptions;
  } catch (error) {
    console.error("Error al obtener tipos de persona:", error);
    return [];
  }
};

export const  gePersonasDoc = async (params) => {

  let _errorMessage = "Se produjo un error al obtener Personas por Documento"; 
  try {
  
    const response = await axios({
      method: "GET",
      url: `${urlBackendPersonas}${epPersonasDoc}`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: params,

 
    });
    if (response.status === 200) {
  
        let _error = "error_code" in response.data;
        if (!_error) {
            return response.data.data;
        }
    }
   
    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
    
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};


export const  getPersonas = async (params) => {
 let _errorMessage = "Se produjo un error al obtener Personas"; 
  try {

   
    const response = await getAxiosClient.get(`${urlBackendPersonas}${epPersonasDoc}`, {
      params: params, 
      headers: {
        "Access-Control-Allow-Origin": "*",
      },// Agrega los params aquí
    });
     
    if (response.status === 200) {
      console.log(response.data);
   
        let _error = "error_code" in response.data;
        if (!_error) {
          console.log(response.data);

            return response.data;
        }
    }
   
    console.log(_errorMessage);


    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
    
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};

export const  getPersonasCuit = async (params) => {
  let _errorMessage = "Se produjo un error al obtener Personas Juridica"; 
console.log(params);
 try {
  const response = await getAxiosClient.get(`${urlBackendPersonas}${epPersonasCuit}`, {
    params: params, 
    headers: {
      "Access-Control-Allow-Origin": "*",
    },// Agrega los params aquí
  });
 
  if (response.status === 200) {
    
   
        let _error = "error_code" in response.data;
        if (!_error) {
          console.log(response.data);

            return response.data;
        }
    }
   
     response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
    
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};
