import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FormularioIngresoCausas from "./formDemandas";

import Message from '../utils/Message';

const initialState = {
  descripcion: "",
  observaciones: "",
  id_bucket: "",
};

const nameItemText = "[Dialog]";
const itemGender = "M";

const TableDialogExpediente = ({
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);

  useEffect(() => {
    if (actionCrud === "create") {
      setItemCrudLocal(initialState);
    } else {
      setItemCrudLocal(itemCrud);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCrud, actionCrud]);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    setItemCrudLocal({
      ...itemCrudLocal,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (itemCrudLocal.descripcion.trim() === "") {
        setMessage({
          severity: "error",
          messageText: "Debes ingresar una descripción",
        });
        setShowMessage(true);
        return;
      }
    }
    save();
  };

  const save = async () => {
    let _itemCrud = {
      descripcion: itemCrudLocal.descripcion,
      observaciones: itemCrudLocal.observaciones,
      id_bucket: itemCrudLocal.id_bucket,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrudLocal.id;
    }

    setIsDoingSomething(true);
    const response = await saveData(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
        title='hola'
      >
        <DialogTitle id="form-dialog-title">
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
              ? "Editar"
              : actionCrud === "enable"
                ? "Habilitar"
                : actionCrud === "read"
                  ? "Detalle"
                  : "Deshabilitar"}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
          <Box sx={{ m: 1, p: 1 }}>
            {itemCrudLocal.id && (
              <Typography variant="h6" component="div" gutterBottom>
                ID: {itemCrudLocal.id}
              </Typography>
            )}

            <TextField
              variant="outlined"
              size="small"
              name="descripcion"
              label="Descripción"
              value={itemCrudLocal.descripcion}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}
              autoFocus={true}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="observaciones"
              label="Observaciones"
              value={itemCrudLocal.observaciones}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 40 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="id_bucket"
              label="ID Bucket"
              value={itemCrudLocal.id_bucket}
              onChange={handleChange}
              disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 40 }}
            />

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  color: "SlateGrey",
                  border: "1px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Creado: {itemCrudLocal.fechaCreacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Última actualización: {itemCrudLocal.fechaActualizacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
            >
              Cancelar
            </Button>

            {(actionCrud !== "read") && (
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                    ? "Habilitar"
                    : "Guardar"}
              </Button>
            )}
        
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableDialogExpediente;
