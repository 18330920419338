import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  IconButton,
  LinearProgress,
  Box,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MUIDataTable from "mui-datatables";
import Message from "../../../utils/Message";
import DocumentacionDialog from "./DocumentacionDialog";
import ConfirmDialog from "../ConfirmDialog";

const initialState = {

  descripcion: "",

  nombre_archivo: "",
};

const DocumentacionTable = ({ documentacion, setDocumentacion }) => {
  const [itemsCrud, setItemsCrud] = useState(documentacion);
  const [itemCrud, setItemCrud] = useState(initialState);
  const [actionCrud, setActionCrud] = useState("");
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    // Inicializa itemsCrud con documentacion
    setItemsCrud(documentacion);
  }, [documentacion]);

  useEffect(() => {
    // Actualiza documentacion cuando itemsCrud cambia
    setDocumentacion(itemsCrud);
  }, [itemsCrud, setDocumentacion]);

  const handleOpenConfirmDialog = (itemCrud) => {
    setItemToDelete(itemCrud);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      const updatedItems = itemsCrud.filter((item) => item.id !== itemToDelete.id);
      setItemsCrud(updatedItems);
      setMessage({
        severity: "error",
        messageText: "Ítem eliminado exitosamente",
      });
      setShowMessage(true);
    }
    handleCloseConfirmDialog();
  };

  const handleDelete = (itemCrud) => {
    handleOpenConfirmDialog(itemCrud);
  };

  const handleClickActionCrud = (itemCrud = {}, action) => {
    setActionCrud(action);
    setItemCrud(action !== "create" ? { ...itemCrud } : initialState);
    setOpenDialog(true);
  };

  const handleDialogSave = (updatedItem) => {
    if (actionCrud === "create") {
      const newItem = { ...updatedItem, id: Date.now() };
      const newItemsCrud = [...itemsCrud, newItem];
      setItemsCrud(newItemsCrud);
    } else if (actionCrud === "update") {
      const updatedItems = itemsCrud.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      );
      setItemsCrud(updatedItems);
    }
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const tableColumns = [

    { name: "descripcion", label: "Descripcion", options: { filter: false, sort: false } },
    { name: "nombre_documento", label: "Documento", options: { filter: false, sort: false } },
    
    {
      name: "actions",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const item = itemsCrud[dataIndex];
          return (
            <>
              <Tooltip title="Ver">
                <IconButton size="small" aria-label="view" onClick={() => handleClickActionCrud(item, "view")}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton size="small" aria-label="update" onClick={() => handleClickActionCrud(item, "update")}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton size="small" aria-label="delete" onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const tableOptions = {
    rowsPerPageOptions: [],
    serverSide: false,
    count: itemsCrud.length,
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setCurrentPage,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 0,
    pagination: false,
    textLabels: {
      body: {
        noMatch: itemsCrud.length === 0 ? "No hay datos para mostrar" : "",
      },
    },
  };

  return (
    <Paper sx={{ width: "100%", mb: 1, border: 'none', boxShadow: 'none' }}>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleConfirmDelete}
        message={`¿Estás seguro de que quieres eliminar el Registro?`}
      />

      {isDoingSomething && <LinearProgress />}
      <Grid container spacing={0} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleClickActionCrud({}, "create")}
            sx={{ mb: 2 }}
          >
            Agregar Documentacion
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ p: 0, textAlign: 'left' }}>
       
          <MUIDataTable
         
            data={itemsCrud}
            columns={tableColumns}
            options={tableOptions}
          />
       
      </Box>
      {openDialog && (
        <DocumentacionDialog
          open={openDialog}
          onClose={handleDialogClose}
          onSave={handleDialogSave}
          itemCrud={itemCrud}
          actionCrud={actionCrud}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
        />
      )}
      <Message
        open={showMessage}
        message={message.messageText}
        severity={message.severity}
        onClose={() => setShowMessage(false)}
      />
    </Paper>
  );
};

export default DocumentacionTable;
