import HttpService from "./HttpService";
HttpService.configure();
const getAxiosClient = HttpService.getAxiosClient();

import {urlPortalBakend, epProvincia} from "../config";
const itemGender = "M";


export const getProvincia = async (params) => {

 let _errorMessage = "Se produjo un error al obtener la Provincia"; 

    try {
      const response = await getAxiosClient.get(`${urlPortalBakend}${epProvincia}`, 
          { params: params, 
        headers: {
            "Access-Control-Allow-Origin": "*",
          }},
        );
     
     
   
    if (response.status === 200) {
        let _error = "error_code" in response.data;
        if (!_error) {
            return response.data.data;
        }
    }
   
    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
    
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};
export const getProvincias = async () => {
  



  let _errorMessage = "Se produjo un error al obtener la Provincia"; 
  try {
    const response = await getAxiosClient.get(`${urlPortalBakend}${epProvincia}`, 
        {  headers: {
          "Access-Control-Allow-Origin": "*",
        }},
      );
    if (response.status === 200) {
        let _error = "error_code" in response.data;
        if (!_error) {
            return response.data.data;
        }
    }
   
    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
    
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};
