export const urlBackendUsuarios = import.meta.env.VITE_URL_BACK_USUARIOS;
export const urlBackendParametros = import.meta.env.VITE_URL_BACK_PARAMETROS;
export const urlBackendPDFBuilder = import.meta.env.VITE_URL_BACK_PDFBUILDER;
export const urlPortalBakend = import.meta.env.VITE_URL_BACK;
export const epFuero = import.meta.env.VITE_API_BACK_FUERO;
export const epProceso = import.meta.env.VITE_API_BACK_PROCESO;
export const epOrganismo = import.meta.env.VITE_API_BACK_ORGANISMO;
export const epMateria = import.meta.env.VITE_API_BACK_MATERIA;
export const epMoneda = import.meta.env.VITE_API_BACK_MONEDA;
export const epTipoDocumento = import.meta.env.VITE_API_BACK_TIPODOCUMENTO;
export const epMateriaVinculo = import.meta.env.VITE_API_BACK_MATERIAVINCULO;
export const epPais = import.meta.env.VITE_API_BACK_PAIS;
export const epDepartamento = import.meta.env.VITE_API_BACK_DEPARTAMENTO;
export const epProvincia = import.meta.env.VITE_API_BACK_PROVINCIA;
export const urlBackendPersonas = import.meta.env.VITE_URL_BACK_PERSONAS;
export const epPers = import.meta.env.VITE_API_BACK_PERS;
export const epPersonasDoc = import.meta.env.VITE_API_BACK_PERSONAS_DOC;
export const epPersonasCuit = import.meta.env.VITE_API_BACK_PERS_BY_CUIT; 



