import React, { useState, useEffect } from "react";
import { Button, Grid, Box, Card, CardContent, Tabs, Tab } from "@mui/material";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CardHeader from '@mui/material/CardHeader';
import PatrocinanteTable from "./patrocinante/PatrocinanteTable.jsx";
import RepresentanteTable from "./representante/RepresentanteTable.jsx";
import DemandadoTable from "./demandado/DemandadoTable.jsx";
import DocumentacionTable from "./documentacion/DocumentacionTable.jsx";
import RelacionadoTable from "./relacionado/RelacionadoTable.jsx";
import ActorTable from "./actor/ActorTable.jsx"
import { getFuero }     from "../../services/FueroService.js";
import { getOrganismo } from "../../services/OrganismoService.js";
import { getProceso } from "../../services/ProcesoService.js";
import { getMateria } from "../../services/MateriaService.js";
import { getMoneda }  from "../../services/MonedaService.js";
import {v4 as uuid} from 'uuid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const FormDemanda = () => {
  const [fueroValue, setFueroValue] = useState('Seleccione Fuero');
  const [procesoValue, setProcesoValue] = useState(null);
  const [circunscripcionValue, setCircunscripcionValue] = useState(null);
  const [organismoValue, setOrganismoValue] = useState("Seleccione Organismo");
  const [materiaValue, setMateriaValue] = useState('Seleccione Materia');
  const [monedaValue, setMonedaValue] = useState(null);
  const [monto, setMonto] = useState("");
  const [showMonedaMonto, setShowMonedaMonto] = useState(false);
  const [fueroOptions, setFueroOptions] = useState([]);
  const [procesoOptions, setProcesoOptions] = useState([]);
  const [organismoOptions, setOrganismoOptions] = useState([]);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [monedaOptions, setMonedaOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [actor, setActor] = useState([]);

  const [demandado, setDemandado] = useState([]);
  const [patrocinante, setPatrocinante] = useState([]);
  const [representante, setRepresentante] = useState([]);
  const [relacionado, setRelacionado] = useState([]);
  const [documentacion, setDocumentacion] = useState([]);
  const [itemCrudLocal, setItemCrudLocal] = useState({
    fuero: null,
    id_fuero: null,
    // Otras propiedades...
  });

  const circunscripcionOptions = [
    { id: 1, label: 'Primera Circunscripción' },
    { id: 2, label: 'Segunda Circunscripción' },
    { id: 3, label: 'Tercera Circunscripción' },
    { id: 4, label: 'Cuarta Circunscripción' },
  ];

 // Fetch fueros and moneda on component mount
 useEffect(() => {
  const fetchInitialData = async () => {
    try {
      setFueroOptions(await getFuero());
   setOrganismoOptions([]);
   setOrganismoValue(null); 
 

    } catch (error) {
      console.error('Error de Inicio de datos ', error);
    }
  };

  fetchInitialData();
}, []);

useEffect(() => {
  const fetchProceso = async () => {
    if (fueroValue) {
      try {
        setProcesoOptions(await getProceso({id_fuero: fueroValue.id}));
        setOrganismoValue(null); 
        setProcesoValue(null);
      } catch (error) {
        console.error('Error fetching proceso', error);
      }
    } else {
      setProcesoOptions([]);
      setProcesoValue(null);
    }
  };
  fetchProceso();
}, [fueroValue]);

useEffect(() => {
  const fetchOrganismo = async () => {
    
    if (fueroValue && circunscripcionValue) {
      try {
        setOrganismoOptions(await getOrganismo({id_fuero: fueroValue.id , circunscripcion_judicial: circunscripcionValue.id}));
        
      } catch (error) {
        console.error('Error fetching organismo', error);
      }
    } else {
      setOrganismoOptions([]);
      
      setOrganismoValue(null);
    }
  };
  fetchOrganismo();
}, [fueroValue, circunscripcionValue]);

useEffect(() => {
  const fetchMateria = async () => {
    if (procesoValue) {
      try {
       
        setMateriaOptions(await getMateria({id_proceso: procesoValue.id}));
              setMonedaOptions(await getMoneda());
        setShowMonedaMonto(false);
      } catch (error) {
        console.error('Error fetching materia', error);
      }
    } else {
      setMateriaOptions([]);
      setShowMonedaMonto(false);
    }
  };
  fetchMateria();
}, [procesoValue]);

useEffect(() => {
  if (materiaValue && materiaOptions.length) {
    const selectedMateria = materiaOptions.find(materia => materia.id === materiaValue.id);
 
    setShowMonedaMonto(selectedMateria ? selectedMateria.solicita_monto : false);

  } else {
    setShowMonedaMonto(false);
  }
}, [materiaValue, materiaOptions]);


  const [tabValue, setTabValue] = useState(0);
  const updatedActors = actor.map(act => {
    const domicilios = [
      act.domicilioReal,
      act.domicilioLegal,
      act.domicilioSocial
    ].filter(domicilio => domicilio); // Filtramos valores falsy (null, undefined, "")
  
    return {
      ...act,
      domicilio_list: domicilios.length > 0 ? domicilios : [] // Si hay domicilios, los asignamos; si no, dejamos el array vacío
    };
  });
  
 
  const actor_actualizado = updatedActors.map(({ id, domicilioReal, domicilioLegal, domicilioSocial, domicilioEspecial, domicilios,tipoPersonActor,tipDocumento,razonSocial,vinculo, ...rest }) => ({
    ...rest,
   
    
  }));
  

  const updatedPatrocinantes = patrocinante.map(pat => {
    const domicilios = [
 
      pat.domicilioLegal,
  
    ].filter(domicilio => domicilio); // Filtramos valores falsy (null, undefined, "")
  
    return {
      ...pat,
      domicilio_list: domicilios.length > 0 ? domicilios : [] // Si hay domicilios, los asignamos; si no, dejamos el array vacío
    };
  });
  
  
  const patrocinante_actualizado = updatedPatrocinantes.map(({ domicilioLegal, domicilios, ...rest }) => ({
    ...rest,
    domicilio_list: [
    
      domicilioLegal,
  
    ].filter(domicilio => domicilio) // También filtramos aquí
  }));
  const documentacion_actualizada = documentacion.map(({ id, ...rest }) => ({
    ...rest  // Excluimos el campo `id` y mantenemos el resto de las propiedades
  }));
  
  const relacionado_actualizado = relacionado.map(({id, ...rest }) => ({ ...rest }));
  

  const handleSubmit = (event) => {
    console.log(actor);
  
    const unique_id = uuid(); 
    console.log("codigo",unique_id);
    event.preventDefault();
     const jsonData = {
   actor_list: actor_actualizado,  
    demandado_list: demandado,
    patrocinante_list: patrocinante_actualizado,
    representante_list: representante,
    relacionado_list: relacionado_actualizado,
    documentacion_list: documentacion_actualizada,
    id_fuero: itemCrudLocal.id_fuero, 
    cj: itemCrudLocal.cj,
    id_organismo:itemCrudLocal.id_organismo,
    id_proceso:itemCrudLocal.id_proceso,
    id_materia:itemCrudLocal.id_materia,
    id_moneda:itemCrudLocal.id_moneda,
    monto:monto,
    };
    console.log(JSON.stringify(jsonData, null, 2));

  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  return (
    <div>

<Card>
      <CardHeader title="Inicio de Demanda" />
      <CardContent>
        <Grid container spacing={2}>
          {/* Fuero Autocomplete */}
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.fuero}>
            <Autocomplete
  id="fuero"
  size="small"
  value={fueroValue}
  onChange={(event, newValue) => {
    setFueroValue(newValue);
    setItemCrudLocal(prev => ({
      ...prev,
      id_fuero: newValue?.id || "",
      fuero: newValue?.label || ""
    }));
  }}
  options={fueroOptions}
  noOptionsText="No hay opciones disponibles"
  renderInput={(params) => (
    <TextField {...params} label="Fuero*" />
  )}
  isOptionEqualToValue={(option, value) => option.id === value.id}
/>

              <FormHelperText>{errors.fuero || "Seleccione fuero"}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Circunscripción Autocomplete */}
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.cj}>
              
<Autocomplete
  id="cj"
  size="small"
  value={circunscripcionValue}
  onChange={(event, newValue) => {
    setCircunscripcionValue(newValue);
       setItemCrudLocal(prev => ({
      ...prev,
      cj: newValue?.id || "",
      circunscripcion: newValue?.label || ""
    }));
  }}
  options={circunscripcionOptions}
  noOptionsText="No hay opciones disponibles"
  renderInput={(params) => (
    <TextField {...params} label="Cricunscripcion*" />
  )}
  isOptionEqualToValue={(option, value) => option.id === value.id}
/>
              <FormHelperText>{errors.cj || "Seleccione circunscripción"}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Organismo Autocomplete */}
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.organismo}>
            <Autocomplete
  id="organismo"
  size="small"
  value={organismoValue}
  onChange={(event, newValue) => {
    setOrganismoValue(newValue);
       setItemCrudLocal(prev => ({
      ...prev,
      id_organismo: newValue?.id || "",
      organismo: newValue?.label || ""
    }));
  }}
  options={organismoOptions}
  noOptionsText="No hay opciones disponibles"
  renderInput={(params) => (
    <TextField {...params} label="Organismo*" />
  )}
  isOptionEqualToValue={(option, value) => option.id === value.id}
/>
              <FormHelperText>{errors.organismo || "Seleccione Organismo"}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Proceso Autocomplete */}
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.proceso}>
            <Autocomplete
  id="proceso"
  size="small"
  value={procesoValue}
  onChange={(event, newValue) => {
    setProcesoValue(newValue);
       setItemCrudLocal(prev => ({
      ...prev,
      id_proceso: newValue?.id || "",
      proceso: newValue?.label || ""
    }));
  }}
  options={procesoOptions}
  noOptionsText="No hay opciones disponibles"
  renderInput={(params) => (
    <TextField {...params} label="Proceso*" />
  )}
  isOptionEqualToValue={(option, value) => option.id === value.id}
/>
              <FormHelperText>{errors.proceso || "Seleccione proceso"}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Materia Autocomplete */}
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.materia}>
              <Autocomplete
                id="materia"
                size="small"
                value={materiaValue}
                onChange={(event, newValue) => {
                  setMateriaValue(newValue);
                     setItemCrudLocal(prev => ({
                    ...prev,
                    id_materia: newValue?.id || "",
                    materia: newValue?.label || ""
                  }));
                }}
                options={materiaOptions}
                noOptionsText="No hay opciones disponibles"
                renderInput={(params) => (
                  <TextField {...params} label="Materia*" />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <FormHelperText>{errors.materia || "Seleccione materia"}</FormHelperText>
            </FormControl>
          </Grid>

          {/* Moneda Autocomplete */}
          {showMonedaMonto && (
            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.moneda}>
                <Autocomplete
                  id="moneda"
                  size="small"
                  value={monedaValue}
                  onChange={(event, newValue) => {
                    setMonedaValue(newValue);
                       setItemCrudLocal(prev => ({
                      ...prev,
                      id_moneda: newValue?.id || "",
                      moneda: newValue?.label || ""
                    }));
                  }}
                  options={monedaOptions}
                  noOptionsText="No hay opciones disponibles"
                  renderInput={(params) => (
                    <TextField {...params} label="Moneda*" />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                <FormHelperText>{errors.moneda || "Seleccione moneda"}</FormHelperText>
              </FormControl>
            </Grid>
          )}

          {/* Monto Input */}
          {showMonedaMonto && (
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="monto"
                label="Monto"
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
                error={!!errors.monto}
                helperText={errors.monto || "Ingrese el monto"}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
      <Box sx={{ margin: "30px" }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            

            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Form Demanda Tabs">
              <Tab label="Actores" />
               <Tab label="Patrocinantes" />
              <Tab label="Representantes" />
              <Tab label="Demandados" />
              <Tab label="Documentación" />
              <Tab label="Expedientes Relacionados" />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <ActorTable actor={actor} setActor={setActor} id_fuero={fueroValue.id} id_materia={materiaValue.id}/>
                </CardContent>
              </Card>
            </TabPanel>
            

            <TabPanel value={tabValue} index={1}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <PatrocinanteTable patrocinante={patrocinante} setPatrocinante={setPatrocinante} id_fuero={fueroValue.id} id_materia={materiaValue.id}/>
                </CardContent>
              </Card>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <RepresentanteTable actor={actor} setActor={setActor} representante={representante} setRepresentante={setRepresentante}  id_fuero={fueroValue.id} id_materia={materiaValue.id} />
                </CardContent>
              </Card>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <DemandadoTable demandado={demandado} setDemandado={setDemandado} id_fuero={fueroValue.id} id_materia={materiaValue.id}/>
                </CardContent>
              </Card>
            </TabPanel>

            <TabPanel value={tabValue} index={4}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <DocumentacionTable documentacion={documentacion} setDocumentacion={setDocumentacion} />
                </CardContent>
              </Card>
            </TabPanel>

            <TabPanel value={tabValue} index={5}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <RelacionadoTable relacionado={relacionado} setRelacionado={setRelacionado} />
                </CardContent>
              </Card>
            </TabPanel>
          </Grid>
        </Grid>
        <Button variant="contained" onClick={handleSubmit}>Generar Demanda</Button>
      </Box>
    </div>
  );
};

export default FormDemanda;
