 import { urlPortalBakend, epProceso } from "../config";
 import HttpService from "./HttpService";
 HttpService.configure();
 const getAxiosClient = HttpService.getAxiosClient();
export const getProceso = async (params) => {
 
      let _errorMessage = "Se produjo un error al obtener el Organismo"; 
      try {
        const response = await getAxiosClient.get( `${urlPortalBakend}${epProceso}`, 
            { params: params, 
          headers: {
              "Access-Control-Allow-Origin": "*",
            }},
          );
    if (response.status === 200) {
        let _error = "error_code" in response.data;
        if (!_error) {
         
            return response.data.data.map(item => ({
                id: item.id,
                label: item.descripcion,
              }));
        }
    }
   
    console.log(_errorMessage);
    console.log(response);

    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";t
    return response;
    
  } catch (error) {
    console.log(_errorMessage);
    if (error.response) {
    
        console.log(error.response);
    } else if (error.request) {
   
        console.log(error.request);
    } else {
        
        console.log(error);
    }
    let response = {};
    response.ok = false;
    response.messageText = _errorMessage;
    response.messageSeverity = "error";
    return response;
}
};