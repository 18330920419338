import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Tooltip,
  
    FormHelperText,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import SaveIcon from "@mui/icons-material/Save";
  import { useEffect, useState } from "react";
  
  const initialState = {
  
    descripcion: "",
    archivo: null,
    nombre_documento: "",
  };
  
  const nameItemText = "Documentación";
  
  const DocumentacionDialog = ({
    onSave,
    onClose,
    itemCrud,
    actionCrud,
    setMessage,
    setShowMessage,
  }) => {
    const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isDoingSomething, setIsDoingSomething] = useState(false);
  
    useEffect(() => {
      if (actionCrud === "create") {
        setItemCrudLocal(initialState);
      } else {
        setItemCrudLocal(itemCrud);
      }
    }, [actionCrud, itemCrud]);
  
    const handleCloseDialog = () => {
      onClose();
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setItemCrudLocal({
        ...itemCrudLocal,
        [name]: value,
      });
  
      if (value.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Este campo es obligatorio",
        }));
      } else {
        setErrors((prevErrors) => {
          const { [name]: removedError, ...rest } = prevErrors;
          return rest;
        });
      }
    };
  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setItemCrudLocal({
        ...itemCrudLocal,
        archivo: file,
        nombre_documento: file ? file.name : "",
      });
      if (!file) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          archivo: "Debe seleccionar un archivo",
        }));
      } else {
        setErrors((prevErrors) => {
          const { archivo, ...rest } = prevErrors;
          return rest;
        });
      }
    };
  
    const handleSave = () => {
      if (actionCrud === "view") return;
  
      const currentErrors = {};
  
  
  
      if (itemCrudLocal.descripcion.trim() === "") {
        currentErrors.descripcion = "El detalle es obligatorio";
      }
  
      if (!itemCrudLocal.archivo) {
        currentErrors.archivo = "Debe seleccionar un archivo";
      }
  
      if (Object.keys(currentErrors).length > 0) {
        setErrors(currentErrors);
        setMessage({
          severity: "error",
          messageText: "Por favor, completa todos los campos obligatorios",
        });
        setShowMessage(true);
        return;
      }
  
      setIsDoingSomething(true);
      setTimeout(() => {
        setIsDoingSomething(false);
        onSave(itemCrudLocal);
      }, 1000);
  
      setMessage({
        messageText: "Datos guardados exitosamente",
        severity: "success",
      });
      setShowMessage(true);
    };
  
    return (
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        {isDoingSomething && <LinearProgress />}
        <DialogTitle
          id="form-dialog-title"
       
        >
          {actionCrud === "create"
            ? `Agregar ${nameItemText}`
            : actionCrud === "view"
            ? `Ver ${nameItemText}`
            : `Modificar ${nameItemText}`}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: "absolute", right: 8, top: 8, color: '#53522C' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="center">
           
            <Grid item xs={12} sm={12}>
              <TextField
                label="descripcion"
                name="descripcion"
                value={itemCrudLocal.descripcion}
                onChange={handleChange}
                fullWidth
                 size="small"
                required
                error={!!errors.detalle}
                helperText={errors.detalle || ""}
                inputProps={{ "aria-label": "Descripcion  de la documentación" }}
                disabled={actionCrud === "view"}
              />
            </Grid>
  
            {/* Input file */}
            <Grid item xs={12} sm={6}>
              <input
                accept="*/*"
                id="upload-file"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                disabled={actionCrud === "view"}
              />
              <label htmlFor="upload-file">
                <Button
                  variant="contained"
                  component="span"
                  fullWidth
                   
                  disabled={actionCrud === "view"}
                >
                  Seleccionar Archivo
                </Button>
              </label>
              <FormHelperText>
                {errors.archivo || ""}
              </FormHelperText>
            </Grid>
  
            {/* Mostrar nombre del archivo */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nombre del Archivo"
                name="nombre_documento"
                   size="small"
                value={itemCrudLocal.nombre_documento}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        {actionCrud !== "view" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              pt: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Tooltip title="Cancelar sin guardar los cambios">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloseIcon />}
                  onClick={handleCloseDialog}
                  aria-label="Cancelar"
                >
                  Cancelar
                </Button>
              </Tooltip>
            </Box>
            <Tooltip title="Guardar la información ingresada">
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSave}
                disabled={isDoingSomething || Object.keys(errors).length > 0}
                aria-label="Guardar"
              >
                {isDoingSomething
                  ? "Guardando..."
                  : actionCrud === "create"
                  ? "Agregar"
                  : "Actualizar"}
              </Button>
            </Tooltip>
          </Box>
        )}
      </Dialog>
    );
  };
  
  export default DocumentacionDialog;
  